/*
  ================================================
    Calculate skew X
  ================================================
*/

function calculateSkewX(wrapClass, innerClass, skewClass, textBody, OnOff) {
  if (OnOff === undefined) {
    OnOff = 1;
  }
  var wrap = document.getElementsByClassName(wrapClass);
  var innerEl = document.getElementsByClassName(innerClass);
  var el = document.getElementsByClassName(skewClass);
  var intViewportWidth = window.innerWidth;
  if (textBody) {
    var textBody = document.getElementsByClassName(textBody);
  }
  var ii = 1; // Used later to define which elemt should change
  for (i = 0; i < wrap.length; i++) {

    /*      START --- Calculation of a skew from an element       */

    var st = window.getComputedStyle(el[i], null); // Gets computed styles of elements

    // Gets transform value matrix
    var tr = st.getPropertyValue("-webkit-transform") ||
      st.getPropertyValue("-moz-transform") ||
      st.getPropertyValue("-ms-transform") ||
      st.getPropertyValue("-o-transform") ||
      st.getPropertyValue("transform") ||
      "Either no transform set, or browser doesn't do getComputedStyle";

    // Splits transform matrix into an array
    var a = tr.split('(')[1],
      a = a.split(')')[0],
      a = a.split(',');

    // Equation to calculate the skew
    var denom = Math.pow(a[0], 2) + Math.pow(a[1], 2),
      skewX = Math.atan2(a[0] * a[2] + a[1] * a[3], denom);

    // Change skew to degrees and possitive numbers
    skewX = skewX / (Math.PI / 180);
    skewX = Math.abs(skewX);

    /*      END --- Calculation of a skew from an element       */

    var normalHeight = wrap[i].offsetHeight;
    innerElement = innerEl[i];

    var xRadians = skewX * Math.PI / 180;
    var extraWidth = normalHeight * Math.tan(xRadians); // Extra width resulted by skewing of an element
    var normalWidth = wrap[i].offsetWidth; // Width of an element without skewing
    var calculatedWidth = normalWidth + extraWidth; // New width of an element after skewing
    $(innerElement).css({ 'width': calculatedWidth, 'margin-left': - extraWidth / 2 });

    if (textBody) {

      if (intViewportWidth <= '767.99') {
        textBodyInc = textBody[i];
        $(textBodyInc).css({ 'margin-left': 0, 'width': '100%' });
      }
      else if (intViewportWidth >= '768' && intViewportWidth <= '991.99') {
        textBodyInc = textBody[i];
        if (OnOff == 1) {
          if (ii == i + 1) {
            if (ii % 2 != 0) {
              $(textBodyInc).css({ 'margin-left': - extraWidth / 2 + 16, 'width': normalWidth });
              ii++;
            }
            else {
              $(textBodyInc).css({ 'margin-left': - extraWidth / 2 + 16, 'width': normalWidth });
              ii = ii + 3;
            }
          }
          else {
            $(textBodyInc).css({ 'margin-left': extraWidth / 2 + 16, 'width': normalWidth });
          }
        }
        else {
          $(textBodyInc).css({ 'margin-left': - extraWidth / 2 + 16, 'width': normalWidth });
        }

      }
      else if (intViewportWidth >= '992') {
        textBodyInc = textBody[i];
        if (OnOff == 1) {
          if (ii == i + 1) {
            if (ii % 4 != 0) {
              $(textBodyInc).css({ 'margin-left': - extraWidth / 2 + 16, 'width': normalWidth });
              ii++;
            }
            else {
              $(textBodyInc).css({ 'margin-left': - extraWidth / 2 + 16, 'width': normalWidth });
              ii = ii + 5;
            }
          }
          else {
            $(textBodyInc).css({ 'margin-left': extraWidth / 2 + 16, 'width': normalWidth });
          }
        }
        else {
          $(textBodyInc).css({ 'margin-left': - extraWidth / 2 + 16, 'width': normalWidth });
        }
      }
    }
  }

};

/*
  ================================================
    Smooth scroll
  ================================================
*/
/*
function smoothScroll() {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });
};*/

/*
  ================================================
    Sticky footer
  ================================================
*/
// when the page load, activate the sticky footer
window.addEventListener("load", activateStickyFooter);

function activateStickyFooter() {
  // need to adjust the footer top when the page just loads
  // because the footer may not be sticky
  adjustStickyFooter();

  // whenever the window is resized, we need to re-adjust the
  // footer top to update its position
  window.addEventListener("resize", adjustStickyFooter);
}

function adjustStickyFooter() {
  var footer = document.querySelector("#footer");
  var footerHeight = footer.offsetHeight;
  var windowHeight = window.innerHeight;
  var html = document.documentElement;
  var pageHeight = html.offsetHeight;

  if ($(footer).hasClass('footer-absolute')) {
    pageFooterHeight = pageHeight + footerHeight;
  } else {
    pageFooterHeight = pageHeight;
  }

  if (windowHeight > pageFooterHeight) {
    footer.classList.add('footer-absolute');
  } else {
    footer.classList.remove('footer-absolute');
  }
}

function adjustPhotoSize(class) {
  var photo = document.querySelectorAll(class), i;

  for (i = 0; i < photo.length; ++i) {
    var photoH = photo[i].offsetHeight;
    var photoW = photo[i].offsetWidth;

    if (photoH / photoW > 1) {
      photo[i].classList.add('person-photo-tall');
    }
  }
}